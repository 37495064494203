import { login, logout, setLogin } from './login'
import { forgotPassword, changePassword, verifyToken } from './password'
import { checkIfUserExists, updateUser, createAccount, checkUserPhone, resubscribeEmail } from './user'

const actions = {
  login,
  logout,
  setLogin,
  verifyToken,
  updateUser,
  createAccount,
  changePassword,
  forgotPassword,
  checkIfUserExists,
  checkUserPhone,
  resubscribeEmail
}

export default actions
