/* eslint-disable no-unused-vars */
import api from '@/utils/api'
export default {
  createNewApplication: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications', payload)
        .then(({ data }) => {
          const { create_application_data } = data.data
          resolve(create_application_data)
        })
        .catch(async (err) => {
          if (err.response) {
            if (err.response.data.message === 'show validate modal') {
              reject(err)
            }
            if (
              err.response.data.type === 'Knockout' &&
              err.response.data.knockout_type === 'approval_below_spending_limit'
            ) {
              reject('approval_below_spending_limit')
            } else if (err.response.data.type === 'Knockout') {
              reject('knockout')
            } else if (err.response.data.type === 'Knockout_email') {
              reject('invalid_email')
            } else if (err.response.data.type === 'invalid_phone_number') {
              reject(err.response.data.message)
            } else if (err.response.data.type === 'stripe_bank_attachement') {
              reject(['stripe_bank_attachement', err.response.data.message])
            } else {
              reject(err.response.data.message)
            }
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },

  checkReturningUserApplication: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/returning-user', payload)
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },

  verifyReturningUserOTP: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/returning-user/verify-otp', payload)
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },

  resendReturningUserOTP: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('applications/returning-user/resend-otp', payload)
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },

  resendVerificationSMS: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('/applications/resend-bank-verification-link', payload)
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },

  resendSMSAnswerSecurityQuestions: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('/applications/resend-security-question-link', payload)
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },

  logFullstoryCurrentSessionUrl: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('/applications/application-initiated', payload, { timeout: 5000 })
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  },

  updateIncomeDetails: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      api
        .post('/applications/income_details', payload)
        .then(({ data }) => {
          resolve(data.data)
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message)
          } else if (err.request) {
            reject(err.request)
          } else {
            reject(err.message || err)
          }
          reject(err)
        })
    })
  }
}
